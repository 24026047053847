@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11.25rem 0;
    background-color: #2c68f3;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Title {
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 3.5625rem;
            text-align: center;
            color: #fff;
        }
        > .Content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            width: 100%;
            margin-top: 3.75rem;
            > .ConsultBtn {
                width: 21.875rem;
                padding: 1.4375rem 0;
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                &:nth-child(1) {
                    border: 2px solid #fff;
                    border-radius: 1rem;
                    background-color: transparent;
                    color: #fff;
                }
                &:nth-child(2) {
                    border: 1px solid #fff;
                    border-radius: 16px;
                    background-color: #fff;
                    color: #2c68f3;
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        padding: 5rem 0;
        > .Inner {
            width: 100%;
            > .Title {
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
            > .Content {
                flex-direction: column;
                gap: 1rem;
                margin-top: 40px;
                > .ConsultBtn {
                    width: 100%;
                    padding: 1.25rem 0;
                    font-size: 1.125rem;
                }
            }
        }
    }
}
