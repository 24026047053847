@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11.25rem 0;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Title {
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 4rem;
        }
        > .Content {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 5rem;
            > .LeftTable {
                width: 50%;
                > .Item {
                    text-align: center;
                    &:nth-child(1) {
                        padding: 2.5rem 0;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 2rem;
                        color: #fff;
                        background-color: #2c68f3;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        padding: 2.125rem 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.5rem;
                        border-width: 0 0 0.0625rem 0;
                        border-style: solid;
                        border-color: #2c68f3;
                        background-color: rgba(232, 240, 255, 0.5);
                    }
                    &:nth-child(4) {
                        font-weight: 600;
                        color: #2c68f3;
                    }
                }
            }
            > .RightTable {
                width: 50%;
                > .Item {
                    text-align: center;
                    &:nth-child(1) {
                        padding: 2.5rem 0;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 2rem;
                        background-color: #e5e7eb;
                    }
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        padding: 2.125rem 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.5rem;
                        color: #6b7684;
                        border-bottom: 1px solid #d1d5db;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        padding: 6.25rem 0;
        > .Inner {
            width: 100%;
            > .Title {
                font-size: 1.75rem;
                line-height: 2.25rem;
                letter-spacing: -0.0313rem;
            }
            > .Content {
                flex-direction: column-reverse;
                margin-top: 2.5rem;
                > .LeftTable {
                    width: 100%;
                    margin-top: 2.5rem;
                    > .Item {
                        text-align: center;
                        &:nth-child(1) {
                            padding: 1.25rem 0;
                            font-size: 1.5rem;
                        }
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            padding: 1.125rem 0;
                            font-size: 1.125rem;
                            border-width: 0 0 0.0625rem 0;
                        }
                        &:nth-child(4) {
                            font-weight: 600;
                            color: #2c68f3;
                            border: 0.125rem solid #2c68f3;
                        }
                    }
                }
                > .RightTable {
                    width: 100%;
                    > .Item {
                        text-align: center;
                        &:nth-child(1) {
                            padding: 1.25rem 0;
                            font-size: 1.5rem;
                        }
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            padding: 1.125rem 0;
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }
}
