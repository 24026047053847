@import 'src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 0.3s;
    &.Active {
        background-color: #fff;
        box-shadow: 0px 0.25rem 0.1875rem rgba(22, 49, 109, 0.1);
    }
    > .Inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 71.25rem;
        height: 5rem;
        padding: 0 1rem;
        > .MenuNav {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            > .Menu {
                padding: 0.75rem 1rem;
                font-style: normal;
                font-weight: 400;
                font-size: 0.9375rem;
                &.Active {
                    font-weight: 600;
                    color: #2c68f3 !important;
                }
            }
            > .DownloadBtn {
                margin-left: 1.25rem;
                padding: 0.5rem 1.25rem;
                font-weight: 500;
                font-size: 0.875rem;
                color: #fff;
                border-radius: 50px;
                background-color: #2c68f3;
            }
        }
        > .MenuBtn {
            display: none;
        }
    }
}

@include mobile {
    .Wrap {
        > .Inner {
            width: 100%;
            height: 3.75rem;
            > .Logo {
                img {
                    width: 4.75rem;
                    height: 1.0556rem;
                }
            }
            > .MenuNav {
                position: fixed;
                right: -999999px;
                padding: 0 1rem;
                transition: all 0.5s;
                &.Active {
                    display: block;
                    width: 100%;
                    height: 100vh;
                    background-color: #fff;
                    top: 3.75rem;
                    left: 0;
                    z-index: 1;
                    > .Menu {
                        display: block;
                        width: 100%;
                        padding: 0.75rem 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.9375rem;
                        &.Active {
                            font-weight: 600;
                            color: #2c68f3 !important;
                        }
                    }
                    > .DownloadBtn {
                        width: 100%;
                        margin: 1.25rem 0 0 0;
                        padding: 0.5rem 1.25rem;
                        font-weight: 500;
                        font-size: 0.875rem;
                        color: #fff;
                        border-radius: 50px;
                        background-color: #2c68f3;
                    }
                }
            }
            > .MenuBtn {
                display: block;
                width: 1.125rem;
                height: 0.875rem;
                position: relative;

                > span {
                    width: 100%;
                    height: 0.125rem;
                    background-color: #222;
                    border-radius: 10px;
                    position: absolute;
                    right: 0;
                    transition: all 0.3s;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: 0.39rem;
                    }
                    &:nth-child(3) {
                        bottom: 0;
                    }
                }
                > &.Active {
                    > span {
                        &:nth-child(1) {
                            -webkit-transform: translateY(6px) rotate(-45deg);
                            transform: translateY(6px) rotate(-45deg);
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            -webkit-transform: translateY(-6px) rotate(45deg);
                            transform: translateY(-6px) rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}
