@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11.25rem 0;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Title {
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 4rem;
        }
        > .Content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2.5rem;
            width: 100%;
            margin-top: 5rem;
            > .LeftItem {
                display: flex;
                flex-direction: column;
                gap: 1.875rem;
                width: 50%;
                > .Item {
                    display: flex;
                    align-items: center;
                    padding: 2.625rem 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.75rem;
                    letter-spacing: -0.05rem;
                    line-height: 2.0625rem;
                    color: #2c68f3;
                    border-radius: 2rem;
                    background-color: #edf7fe;
                    > img {
                        margin-right: 1rem;
                    }
                }
            }
            > .RightItem {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 50%;
                height: 35.625rem;
                border-radius: 2.5rem;
                background-color: #2c68f3;
                overflow: hidden;
            }
        }
    }
}

@include mobile {
    .Wrap {
        padding: 6.25rem 0;
        > .Inner {
            width: 100%;

            > .Title {
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
            > .Content {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: unset;
                gap: 2.5rem;
                width: 100%;
                margin-top: 3.75rem;
                > .LeftItem {
                    gap: 1.25rem;
                    width: 100%;
                    > .Item {
                        width: 100%;
                        padding: 1.125rem;
                        font-size: 1.125rem;
                        line-height: 2.0625rem;
                        border-radius: 1.25rem;
                        > img {
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-right: 0.875rem;
                        }
                    }
                }
                > .RightItem {
                    width: 100%;
                    height: auto;
                    min-width: auto;
                    border-radius: 1.25rem;
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
