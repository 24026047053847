@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 29.1875rem;
    border-top: 1px solid #cccdd1;
    background-color: #f7f8fb;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            margin-top: 3.625rem;
            > .Items {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 50%;
                > .Item {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    > span {
                        font-weight: 700;
                    }
                }
            }
        }
        > .Bottom {
            display: flex;
            align-items: flex-end;
            width: 100%;
            margin-top: 2.5rem;
            > .Copy {
                width: 50%;
                font-style: normal;
                font-weight: 400;
                font-size: 0.625rem;
                color: #a3a5aa;
            }
            > .Download {
                display: flex;
                gap: 1rem;
                width: 50%;
                > img {
                    cursor: pointer;
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        height: auto;
        > .Inner {
            width: 100%;
            padding: 5rem 1rem;
            > .Content {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 1.25rem;
                margin-top: 1.875rem;
                > .Items {
                    width: 100%;
                    > .Item {
                        font-size: 0.875rem;
                    }
                }
            }
            > .Bottom {
                align-items: flex-start;
                flex-direction: column-reverse;
                margin-top: 2.5rem;
                > .Copy {
                    width: 100%;
                }
                > .Download {
                    width: 100%;
                    margin-bottom: 1.875rem;
                    > img {
                        cursor: pointer;
                        width: 40%;
                        height: auto;
                    }
                }
            }
        }
    }
}
