@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 46.875rem;
    background-color: #edf7fe;
    overflow: hidden;
    > .Inner {
        display: flex;
        justify-content: space-between;
        width: 71.25rem;
        padding: 0 1rem;
        > .LeftText {
            margin-top: 4.375rem;
            > .MainTitle {
                font-style: normal;
                font-weight: 700;
                font-size: 3rem;
                color: #2c68f3;
                > .Title {
                    display: inline-block;
                    margin-top: 0.875rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3rem;
                    color: #1d2c47;
                }
            }
            > .MainInfo {
                margin: 4rem 0 0.875rem;
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.5rem;
                letter-spacing: -0.0625rem;
            }
            > .SubInfo {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #6b7684;
            }
            > .Download {
                display: flex;
                gap: 0.625rem;
                margin-top: 1.875rem;
            }
            > .WebPage {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 43px;
                margin-right: 22px;
                margin-top: 1rem;
                > .WebPageButton {
                    width: 100%;
                    height: 100%;
                    background: #2c68f3;
                    border-radius: 6px;
                    color: white;
                    font-size: 15px;
                    font-weight: bold;
                    }
            } 
        }
        > .RightImg {
            height: 38.875rem;
        }
    }
}

@include mobile {
    .Wrap {
        align-items: center;
        height: auto;
        overflow: visible;
        > .Inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 6.25rem 1rem 0;
            > .LeftText {
                margin-top: 0;
                > .MainTitle {
                    font-size: 2rem;
                    > .Title {
                        font-size: 2rem;
                    }
                }
                > .MainInfo {
                    margin: 2.5rem 0 0.875rem;
                    font-size: 1.125rem;
                }
                > .SubInfo {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                }
            }
            > img {
                width: 100%;
                height: auto;
                margin-top: 1.875rem;
            }
        }
    }
}
