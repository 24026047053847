@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 63.625rem;
    overflow: hidden;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > .Item {
                > .SubTitle {
                    margin-top: 10.8125rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: #2c68f3;
                }
                > .MainTitle {
                    margin: 1.25rem 0 5rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 4rem;
                }
                > .Info {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                    color: #6b7684;
                }
                > img {
                    margin-top: 1.5rem;
                }
                &:nth-child(2) {
                    img {
                        width: 80%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        height: auto;
        > .Inner {
            width: 100%;
            padding: 6.25rem 1rem;
            > .Content {
                flex-direction: column-reverse;
                gap: 2.5rem;
                > .Item {
                    > .SubTitle {
                        margin-top: 0;
                        font-size: 1.25rem;
                    }
                    > .MainTitle {
                        margin: 1.25rem 0 2.5rem;
                        font-size: 1.75rem;
                        line-height: 2.25rem;
                    }
                    > .Info {
                        font-size: 1.125rem;
                        line-height: 1.5rem;
                    }
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
