@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95.25rem;
    position: relative;
    overflow: hidden;
    > .BannerBg {
        width: 100%;
        height: 46.875rem;
        background-color: #edf7fe;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 3.5625rem;
            text-align: center;
            > .Info {
                max-width: 505px;
                margin-top: 2.5rem;
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #6b7684;
            }
            > .Words {
                line-height: 4.5rem;
                color: #c0d5ff;
                > span {
                    color: #2c68f3;
                }
            }
            &.ImgGroup {
                height: 35rem;
                margin: 1.25rem 0 10.9375rem;
                position: relative;
                > img {
                    position: absolute;
                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                    }
                    &:nth-child(2) {
                        bottom: 0;
                        left: 20%;
                    }
                    &:nth-child(3) {
                        top: 4.8125rem;
                    }
                    &:nth-child(4) {
                        bottom: 0;
                        right: 20%;
                    }
                    &:nth-child(5) {
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        height: auto;
        position: relative;
        > .BannerBg {
            width: 100%;
            height: 26.25rem;
            background-color: #edf7fe;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        > .Inner {
            width: 100%;
            padding: 7.5rem 1rem;
            > .Item {
                font-size: 2.25rem;
                line-height: 2.625rem;
                > .Title {
                    width: 72%;
                }
                > .Info {
                    max-width: 100%;
                }
                > .Words {
                    margin-top: 12.5rem;
                    font-size: 1.4375rem;
                    line-height: 2.25rem;
                }
                &.ImgGroup {
                    height: auto;
                    margin: 3.75rem 0 7.5rem;
                    position: relative;
                    > img {
                        position: absolute;
                        width: 7.5rem;
                        height: auto;
                        &:nth-child(1) {
                            left: 0;
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                        &:nth-child(3) {
                            top: 0;
                        }
                        &:nth-child(4) {
                            top: 0;
                            right: 0;
                        }
                        &:nth-child(5) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
