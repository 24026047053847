@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 47rem;
    background-color: #edf7fe;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            > .Item {
                > .Title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 3rem;
                    line-height: 4.25rem;
                    > span {
                        color: #2c68f3;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        height: auto;
        > .Inner {
            width: 100%;
            padding: 5rem 1rem;
            > .Content {
                flex-direction: column;
                justify-content: center;
                > .Item {
                    > .Title {
                        font-size: 1.75rem;
                        line-height: 2.25rem;
                        text-align: center;
                        margin-bottom: 2.5rem;
                    }
                    &:nth-child(2) {
                        display: flex;
                        justify-content: center;
                        > img {
                            width: 90%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}
