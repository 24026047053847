@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Apple SD Gothic Neo', Pretendard, Roboto,
        'Noto Sans KR', 'Segoe UI', 'Malgun Gothic', sans-serif;
    color: #222222;
    -webkit-font-smoothing: antialiased;
}

a {
    color: #222222 !important;
    text-decoration: none !important;
    cursor: pointer;
}

* {
    box-sizing: border-box;
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Apple SD Gothic Neo', Pretendard,
        Roboto, 'Noto Sans KR', 'Segoe UI', 'Malgun Gothic', sans-serif;
}

div,
a,
video,
button,
input,
textarea,
select {
    outline: none !important;
    &:hover {
        outline: none !important;
    }
    &:-webkit-any-link:focus {
        outline: none !important;
    }
    &:focus {
        outline: none !important;
    }
}
a,
button {
    border: 0;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }
}

// 반응형 화면 크기
$mobile: 767px;
$tablet: 1023px;
$addtop: 1024px;
$desktop: 1590px;

// 반응형, 브라우저 767px 이하일때
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}
// 반응형, 브라우저 크기가 768이상, 1023px 이하일때
@mixin tablet {
    @media (min-width: ($mobile + 1)) and (max-width: $tablet) {
        @content;
    }
}

// 반응형, 브라우저 크기가 1024px 이상, 1590px 이하일때
@mixin addtop {
    @media (min-width: $addtop) and (max-width: $desktop) {
        @content;
    }
}

// 반응형, 브라우저 크기가 1670px 이상일때
@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}
