// 반응형 화면 크기
$mobile: 767px;
$tablet: 1023px;
$addtop: 1024px;
$desktop: 1590px;

// 반응형, 브라우저 767px 이하일때
@mixin mobile {
    @media (max-width: $mobile) {
        @content;
    }
}

// 반응형, 브라우저 크기가 768이상, 1023px 이하일때
@mixin tablet {
    @media (min-width: ($mobile + 1)) and (max-width: $tablet) {
        @content;
    }
}

// 반응형, 브라우저 크기가 1024px 이상, 1590px 이하일때
@mixin addtop {
    @media (min-width: $addtop) and (max-width: $desktop) {
        @content;
    }
}

// 반응형, 브라우저 크기가 1670px 이상일때
@mixin desktop {
    @media (min-width: $desktop) {
        @content;
    }
}
