@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11.25rem 0;
    background-color: #f7f8fb;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Content {
            width: 100%;
            height: 45.75rem;
            position: relative;
            > .Item {
                position: absolute;
                &:nth-child(1) {
                    top: 0;
                    right: 0;
                }
                &:nth-child(2) {
                    bottom: 0;
                    left: 0;
                    max-width: 38.1875rem;
                    padding: 2.5rem;
                    box-shadow: 0px 0.25rem 3.125rem rgba(22, 49, 109, 0.1);
                    border-radius: 2.5rem;
                    background-color: #fff;
                }
                > .ImgInfo {
                    margin-top: 2rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    text-align: right;
                }
                > .MainTitle {
                    margin-bottom: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                }
                > .Info {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                    color: #6b7684;
                    &:last-child {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
    > .MobileInner {
        width: 100%;
        padding: 0 1rem;
        > .Content {
            width: 100%;
            > .Item {
                width: 100%;
                &:nth-child(1) {
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
                &:nth-child(2) {
                    margin-top: 3.75rem;
                }
                > .ImgInfo {
                    margin-top: 2rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                > .MainTitle {
                    margin-bottom: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                }
                > .Info {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.25rem;
                    line-height: 2.5rem;
                    color: #6b7684;
                    &:last-child {
                        margin-top: 1.25rem;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        padding: 5rem 0;
        > .Inner {
            width: 100%;
            > .Content {
                height: auto;
                > .Item {
                    position: unset;
                    &:nth-child(1) {
                        > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    &:nth-child(2) {
                        max-width: 100%;
                        margin-top: 2.5rem;
                        padding: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background-color: transparent;
                    }
                    > .ImgInfo {
                        margin-top: 1.75rem;
                        font-size: 1.125rem;
                        text-align: left;
                        line-height: 1.5rem;
                        letter-spacing: -0.0375rem;
                    }
                    > .MainTitle {
                        font-size: 1.75rem;
                    }
                    > .Info {
                        font-size: 1rem;
                        line-height: 1.375rem;
                    }
                }
            }
        }
    }
}
