@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11.25rem 0;
    background-color: #1d2c47;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Title {
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 4rem;
            color: #fff;
        }
        > .Content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 2.5rem;
            width: 100%;
            margin-top: 5rem;
            > .Item {
                width: calc(50% - 20px);
                height: 20rem;
                max-width: 34.375rem;
                padding: 2.5rem;
                border-radius: 2.5rem;
                background-color: #fff;
                position: relative;
                > .Title {
                    margin-bottom: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                }
                > .Info {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                    color: #6b7684;
                    > span {
                        margin-top: 0.375rem;
                        font-weight: 600;
                        color: #2c68f3;
                    }
                }
                > img {
                    position: absolute;
                    right: 1.875rem;
                    bottom: 1.375rem;
                }
            }
        }
    }
}

@include mobile {
    .Wrap {
        padding: 6.25rem 0;
        > .Inner {
            width: 100%;
            > .Title {
                font-size: 1.75rem;
                line-height: 2.25rem;
            }
            > .Content {
                flex-direction: column;
                align-items: unset;
                gap: 1.25rem;
                width: 100%;
                margin-top: 2.5rem;
                > .Item {
                    width: 100%;
                    height: 18.75rem;
                    max-width: auto;
                    padding: 1.875rem 1.25rem;
                    border-radius: 1.25rem;
                    > .Title {
                        margin-bottom: 1.5rem;
                        font-size: 1.625rem;
                    }
                    > .Info {
                        font-size: 1.125rem;
                        line-height: 1.5rem;
                    }
                    > img {
                        position: absolute;
                        right: 1.25rem;
                        bottom: 1.25rem;
                    }
                }
            }
        }
    }
}
