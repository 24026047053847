@import '/src/component/Utils.scss';

.Wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 11.25rem 0 15.625rem;
    > .Inner {
        width: 71.25rem;
        padding: 0 1rem;
        > .Content {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 67.375rem;
            margin-top: 11.25rem;
            position: relative;
            > .Item {
                &:nth-child(1) {
                    > img {
                        width: 100%;
                        height: auto;
                    }
                }
                &:nth-child(2) {
                    position: absolute;
                    bottom: 0;
                    max-width: 63.75rem;
                    padding: 3.125rem;
                    box-shadow: 0px 0.25rem 3.125rem rgba(22, 49, 109, 0.1);
                    border-radius: 2.5rem;
                    background-color: #fff;
                }
                > .MainTitle {
                    margin-bottom: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2rem;
                }
                > .Info {
                    margin-bottom: 1.625rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.25rem;
                    color: #6b7684;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    > .InfoTitle {
                        margin-bottom: 0.625rem;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1.5rem;
                        color: #222;
                    }
                }
            }
        }
        > .Item {
            margin-top: 15.625rem;
            font-style: normal;
            font-weight: 700;
            font-size: 3rem;
            line-height: 4.5rem;
            text-align: center;
            color: #2c68f3;
        }
    }
}

@include mobile {
    .Wrap {
        padding: 7.5rem 0;
        > .Inner {
            width: 100%;
            > .Content {
                flex-direction: column;
                height: auto;
                margin-top: 0;
                position: unset;
                > .Item {
                    &:nth-child(1) {
                        > img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    &:nth-child(2) {
                        position: unset;
                        max-width: 100%;
                        margin-top: 2.5rem;
                        padding: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background-color: transparent;
                    }
                    > .MainTitle {
                        font-size: 1.75rem;
                    }
                    > .Info {
                        margin-bottom: 1.25rem;
                        font-size: 0.9375rem;
                        line-height: 1.375rem;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        > .InfoTitle {
                            font-size: 1.125rem;
                        }
                    }
                }
            }
            > .Item {
                margin-top: 6.25rem;
                font-size: 1.25rem;
                line-height: 1.875rem;
            }
        }
    }
}
